
import { useGrosseryStore } from '@/stores/grossery'
import { useMarketStore } from '~/stores/market'

export default {
  name: 'GrosseryCart',
  setup() {
    const marketStore = useMarketStore()
    const GrosseryStore = useGrosseryStore()
    return {
      marketStore,
      GrosseryStore,
    }
  },
  data() {
    return {
      selected_parnters: 'ather_parnter',
      keenSliderOptions: {
        breakpoints: {
          '(min-width: 400px)': {
            slides: { perView: 4, spacing: 5 },
          },
          '(min-width: 768px)': {
            slides: { perView: 3, spacing: 8 },
          },
          '(min-width: 1000px)': {
            slides: { perView: 4, spacing: 8 },
          },
          '(min-width: 900px)': {
            slides: { perView: 11, spacing: 8 },
          },
        },
        slides: { perView: 4, spacing: 8 },
      },
    }
  },
  computed: {
    listGroserryPartners() {
      const partners =
        this.marketStore.cart?.sellers.filter(
          partner => partner?.id == this.GrosseryStore.grossery_merchant_uid,
        ) || []
      return this.mixPartners(partners)
    },
    listAtherPartners() {
      const partners =
        this.marketStore.cart?.sellers.filter(
          partner => partner?.id !== this.GrosseryStore.grossery_merchant_uid,
        ) || []
      return this.mixPartners(partners)
    },
  },
  beforeDestroy() {
    this.GrosseryStore.showPopupChooseGrosseryCheckout = false
    this.GrosseryStore.showPopupChooseGrosseryCheckoutCredit = false
    this.GrosseryStore.showPopupCheckoutCreditNotAvailable = false
  },
  methods: {
    mixPartners(partners) {
      const products =
        partners
          .map(partner => {
            return partner.line_items.map(item => {
              return {
                product_id: item?.product_id,
                id: item?.id,
                image_url: item?.image_url,
                is_selected_for_checkout: item?.is_selected_for_checkout,
                state: item?.state,
                availability_check_needed: item?.availability_check_needed,
                availability_check_state: item?.availability_check_state,
              }
            })
          })
          .flat() || []
      return products.filter(product => {
        return (
          product.is_selected_for_checkout &&
          product.state === 'active' &&
          (product?.availability_check_needed == false ||
            product?.availability_check_state === 'AVAILABLE')
        )
      })
    },
    setLogoUrl(logo_url) {
      return logo_url || '/images/no-pos-found.svg'
    },
    async confirmCheckout() {
      if (this.selected_parnters === 'partner_grossery') {
        this.GrosseryStore.updateTypeGrosseryCartMode('GROCERY')
      }
      if (this.selected_parnters === 'ather_parnter') {
        this.GrosseryStore.updateTypeGrosseryCartMode('NOT_GROCERY')
      }
      await this.marketStore.getCart()
      this.GrosseryStore.showPopupChooseGrosseryCheckout = false
      this.GrosseryStore.continueCheckoutWithoutGrossery = true
    },
    async confirmCheckoutCredit() {
      this.GrosseryStore.updateTypeGrosseryCartMode('NOT_GROCERY')
      await this.marketStore.getCart()
      this.GrosseryStore.showPopupChooseGrosseryCheckoutCredit = false
      this.GrosseryStore.continueCreditCheckoutWithoutGrossery = true // REDIRECT TO CHECKOUT
    },
  },
}
